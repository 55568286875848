<template>
  <v-container fluid class="geococde-map-container">
    <GmapMap
      ref="mapRef"
      :center="JSON.parse(center)"
      :zoom="parseInt(zoom)"
      :options="{
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false
      }"
      :style="`width: 100%; min-height: ${height}px`"
    >
    </GmapMap>
  </v-container>
</template>
<style lang="scss">
.geococde-map-container {
  height: 100%;
  margin-bottom: 40px;
  #map {
    overflow: auto;
  }
}
</style>
<script>
// import axios from "~/plugins/axios";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "GeocodeMap",
  props: {
    center: {
      type: String
    },
    zoom: {
      type: String
    },
    height: {
      type: String
    }
  },
  data: () => ({
    polygon: null
  }),
  computed: {
    google: gmapApi
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then(map => {
      // map.panTo({ lat: 1.38, lng: 103.8 });
      map.data.loadGeoJson("/ebsn/api/address/geo-service-coverage");
      map.data.setStyle({
        fillColor: "#13A538",
        strokeWeight: 2
      });
    });
    console.log("GMaps:", this.google.maps);
  }
};
</script>
