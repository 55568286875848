<template>
  <div class="shop-at-work-subscription">
    <v-form ref="form" v-model="isValid" class="d-flex">
      <v-text-field
        v-model="companyCode"
        color="primary"
        :label="$t('shopAtWork.inputText')"
        outlined
        dense
        :rules="[requiredRules()]"
        required
      ></v-text-field>
      <v-btn
        class="subscribe-button"
        height="40"
        color="primary"
        @click="subscribeCompany()"
        large
        depressed
        >{{ $t("shopAtWork.subscribeBtn") }}</v-btn
      >
    </v-form>
    <ResponseMessage :response="response" class="mt-1" />
  </div>
</template>
<style lang="scss">
.shop-at-work-subscription {
}
</style>
<script>
import ResponseMessage from "@/components/ResponseMessage";

import cartInfo from "~/mixins/cartInfo";

import DeliveryService from "~/service/deliveryService";

import { requiredValue } from "~/validator/validationRules";

export default {
  name: "ShopAtWorkSubscription",
  mixins: [cartInfo],
  components: { ResponseMessage },
  data() {
    return {
      isValid: false,
      companyCode: null,
      response: {},
      requiredRules: requiredValue
    };
  },
  methods: {
    async subscribeCompany() {
      if (this.$refs.form.validate()) {
        this.response = {};
        // eslint-disable-next-line no-unused-vars
        let res = await DeliveryService.subscribeShopAtWork(this.companyCode);
        // se ok, apro address selector
        if (res?.response.status === 0) {
          this.$router.push({
            name: "AddressSelectorView",
            query: { deliveryServiceId: 5 }
          });
        } else if (res?.response) {
          this.response = res.response;
        }
      }
    }
  }
};
</script>
