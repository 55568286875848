<template>
  <div v-if="posts" class="w-100 slider-main-wrapper card-slider">
    <swiper :options="swiperOption" ref="swiperRef" class="w-100 h-100">
      <swiper-slide v-for="post in posts" :key="post.id">
        <component
          :is="isRecipe ? 'RecipeCard' : 'ArticleCard'"
          :recipe="post"
          :posts="post"
        ></component>
      </swiper-slide>
    </swiper>
    <div
      v-if="showBullets && posts.length > 1"
      :id="`card-slider-pagination-${paginationClass}`"
      class="swiper-pagination"
    ></div>
    <div
      v-if="showArrows && posts.length > 1 && $vuetify.breakpoint.mdAndUp"
      :id="`card-slider-slider-prev-${paginationClass}`"
      class="swiper-button-prev"
    ></div>
    <div
      v-if="showArrows && posts.length > 1 && $vuetify.breakpoint.mdAndUp"
      :id="`card-slider-slider-next-${paginationClass}`"
      class="swiper-button-next"
    ></div>
  </div>
</template>
<style scoped lang="scss">
.article-detail,
.recipe-detail {
  .slider-main-wrapper {
    position: relative;
    .swiper-slide {
      height: 460px;
    }
  }
}
</style>
<script>
import RecipeCard from "@/components/wordpress/RecipeCard.vue";
import ArticleCard from "@/components/wordpress/ArticleCard.vue";
import CMService from "~/service/cmService";
export default {
  name: "CardSlider",
  components: { RecipeCard, ArticleCard },
  props: {
    isRecipe: {
      type: Boolean,
      required: false,
      default: false
    },
    course: {
      type: String,
      required: false
    },
    cat: {
      type: String,
      required: false
    },
    paginationClass: { type: String, required: false },
    showArrows: { type: Boolean, required: false, default: true },
    showBullets: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      posts: null,
      swiperOption: {
        slidesPerView: 1.2,
        spaceBetween: 10,
        autoHeight: true,
        watchOverflow: true,
        keyboard: {
          enabled: true
        },
        pagination: {
          el: `#card-slider-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#card-slider-slider-prev-${this.paginationClass}`,
          nextEl: `#card-slider-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          600: {
            slidesPerView: 2.2,
            slidesPerGroup: 2,
            spaceBetween: 15
          },
          960: {
            slidesPerView: 3.3,
            slidesPerGroup: 3
          },
          1264: {
            slidesPerView: 4.4,
            spaceBetween: 20,
            slidesPerGroup: 4
          }
        }
      }
    };
  },
  mounted() {
    this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      let params = [
        {
          filterName: "orderby",
          filterValue: "date"
        },
        {
          filterName: "order",
          filterValue: "desc"
        }
      ];
      if (this.course != null && this.course.length > 0) {
        params.push({
          filterName: "filter[course]",
          filterValue: this.course
        });
      }
      if (this.cat != null && this.cat.length > 0) {
        params.push({
          filterName: "categories",
          filterValue: this.cat
        });
      }
      const res = await CMService.getCustomPostByFilters(
        this.isRecipe ? "recipe" : "posts",
        params
      );
      console.log("ressssss: " + res.data);

      if (res && res.data && res.data.length) {
        this.posts = res.data;
      }
    }
  }
};
</script>
