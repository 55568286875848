<template>
  <div class="page">
    <div v-if="notFound" class="text-center">
      <v-img
        alt="logo Basko"
        width="300px"
        height="57px"
        style="margin: 0 auto;"
        src="/logo/logo.svg"
      />
      <h1 class="font-weight-bold">Pagina non trovata!</h1>
      <h3>Ci dispiace. La pagina che hai cercato non è stata trovata.</h3>
      <h4>Ti invitiamo a effettuare una nuova ricerca.</h4>
      <v-btn
        color="primary"
        depressed
        style="text-decoration: none"
        class="font-weight-bold"
        :to="{ name: 'Home' }"
      >
        TORNA ALLA HOMEPAGE
      </v-btn>
    </div>
    <component
      :is="templateName"
      :key="key"
      :page="page"
      :additionalData="additionalData"
    ></component>
  </div>
</template>
<script>
import PageContainer from "@/components/wordpress/PageContainer.vue";
import PageFullWidth from "@/components/wordpress/PageFullWidth.vue";
import clickHandler from "~/mixins/clickHandler";
import page from "~/mixins/page";

export default {
  mixins: [clickHandler, page],

  data() {
    return { page: {}, key: 1, additionalData: null, notFound: false };
  },

  created() {
    if (this.$route.params.additionalData) {
      this.additionalData = this.$route.params.additionalData;
    }
    this.fetchPage(this.$route.params.postName);
  },
  components: { PageContainer, PageFullWidth },
  computed: {
    templateName() {
      switch (this.page.template) {
        case "page-fullwidth.php":
          return "PageFullWidth";
        default:
          return "PageContainer";
      }
    }
  },
  watch: {
    "$route.params.postName": function(postName) {
      this.fetchPage(postName);
    }
  }
};
</script>
